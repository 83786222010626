import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { API_URL } from "../../../utils/ApiUrl";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import useEthBalance from "../../../hooks/userBalance";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
const Copied = styled("span")`
  font-size: 16px;
  margin-left: 10px;
`;
const HOVER_TIMEOUT = 1000;

const Navbar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggleBtn, setToggleBtn] = useState(false)
  console.log("🚀 ~ file: Navbar.js ~ line 21 ~ Navbar ~ toggleBtn", toggleBtn)

  const users = useSelector((state) => state.UserReducer.GetUserAll);
  // console.log("user data", users);
  const { account } = useWeb3React();
  const [inputs, setInputs] = useState();
  const [copy, setCopy] = useState(false);
  // const [open, setOpen] = useState(false);
  const { login, logout } = useAuth();

  let ethBalance = useEthBalance();
  // console.log("ethBalance", ethBalance.toNumber() / 10**18)
  const balance = (ethBalance.toNumber() / 10 ** 18).toFixed(3)
  // console.log("balance of user", balance);

  const connectMetamask = () => {
    localStorage.setItem("connectorId", "injected");
    login("injected");
    localStorage.setItem("flag", "true");
    window.$("#exampleModal").modal("hide");
  };

  const trustWallet = async () => {
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout();
    } else {
      login("walletconnect");
      window.$("#exampleModal").modal("hide");
    }
  };

  const logout1 = () => {
    localStorage.setItem("flag", "false");
    // localStorage.clear();
    logout();
  };

  const [showsidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  return (
    <section className="main-navbar-mobile main-navbar">
      <div className="container-fluid  p-0">
        <div className="row">
          <div className="col-xl-11 col-lg-12 col-12  m-auto   nav_boder ">
            <nav className="navbar  navbar-expand-xl p-0">
              <a href="#" className="navbar-brand animate__flash" >
                <Link to="/">
                  <img
                    src="\DNS-logo.svg"
                    alt="img"
                    className="img-fluid animate__animated animate__bounce"
                  />
                </Link>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleShowSidebar}
              >
                <span className="togg">
                  <i class="fas fa-bars"></i>
                </span>
              </button>
              <div
                className="collapse navbar-collapse marg"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav m-auto">
                  <li class="nav-item active">
                    <HashLink to="#navbar" style={
                      isActive("")
                        ? {
                          color: "#1777e2", borderBottom: "2px solid #1777e2"
                        }
                        : {}}>Home</HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink to="#"> Features</HashLink>
                  </li>
                  <li className="nav-item">

                    <HashLink to="#about" style={
                      isActive("#about")
                        ? {
                          color: "#1777e2", borderBottom: "2px solid #1777e2"
                        }
                        : {}}>About</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="#roadmap" style={
                      isActive("#roadmap")
                        ? {
                          color: "#1777e2", borderBottom: "2px solid #1777e2"
                        }
                        : {}}> RoadMap</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="#faqs" style={
                      isActive("#faqs")
                        ? {
                          color: "#1777e2", borderBottom: "2px solid #1777e2"
                        }
                        : {}}> FAQs</HashLink>
                  </li>


                </ul>
                {account ? (
                  <button
                    class="btn button-hedaer"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    type="button"
                  >
                    Connected
                  </button>
                ) : (
                  <a
                    class="btn button-hedaer"
                    href="https://app.hydtprotocol.com/"
                    target="_blank"
                  >
                    <img
                      src="\luchbtnn.svg"
                      alt="img"
                      className="img-fluid "
                    />
                    Launch App
                  </a>
                )}

                {account ? (
                  <div className="nav-drop">
                    <a class="dropdown">
                      <button
                        class="btn button-hedaer dropdown-toggle fcccctt"
                        type="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="true"
                        onClick={() => setToggleBtn(!toggleBtn)}
                      >
                        <img
                          src={users?.picture ? users?.picture : '/walletimage.svg'}
                          className="img-fluid rounded-circle w20 mr-2 img200"
                          data-toggle="modal"
                          data-target="#exampleModal"
                          alt=""
                        />
                        <span className="strong mr-2">{users?.userName}</span>
                      </button>
                      {toggleBtn && (
                        <div class="mydropdown ">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="inner-drop">
                                <div className="boxy"></div>
                                <h3 className="">{users?.userName}</h3>
                                <span className="d-flex align-items-center">
                                  <p class="text-truncate">{account}</p>
                                  <div>
                                    {copy ? (
                                      <Copied>Copied</Copied>
                                    ) : (
                                      // {account}
                                      <CopyToClipboard
                                        text={account}
                                        onCopy={() => {
                                          setCopy(true);
                                          setTimeout(
                                            () => setCopy(false),
                                            HOVER_TIMEOUT
                                          );
                                        }}
                                      >
                                        {/* <div>
                          {account}
                            </div> */}
                                        <a href="#"><img
                                          src="\copy-fav.png"
                                          className="img-fluid"
                                          alt=""
                                        /></a>
                                      </CopyToClipboard>
                                    )}
                                  </div>
                                </span>
                                <ul className="list-inline mgbt">
                                  <li className="list-inline-item">
                                    <img
                                      src="\eth-drop.png"
                                      className="img-fluid  w18"
                                      alt=""
                                    />
                                  </li>
                                  <li className="list-inline-item">
                                    <div className="inner-blnc">
                                      <h6 className="grey">Balance</h6>
                                      <h4 className="">
                                        <span className="common-text">
                                          {balance ? balance : 0} <span>ETH</span>{" "}
                                        </span>
                                      </h4>
                                    </div>
                                  </li>
                                </ul>
                                <div className="bottom-text">
                                  <Link to="/profile">
                                    <img
                                      src="\profile-drop.svg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                    <p>My Profile</p>
                                  </Link>
                                </div>
                                <div class="dropdown-divider"></div>
                                <div className="bottom-text">
                                  <Link class=" " to="/editprofile">
                                    <img
                                      src="\edit-drop.svg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                    <p> Edit Profile</p>
                                  </Link>
                                </div>
                                <div class="dropdown-divider"></div>
                                <div className="bottom-text" onClick={logout1}>
                                  <img
                                    src="\disconnect-drop.svg"
                                    className="img-fluid"
                                    alt=""
                                  />
                                  <p> Disconnect</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </nav>
          </div>
        </div>

      </div>
      {/* 
      <div className="wallet-modal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div className="cross-icon">
                  <h3 class="modal-title text-center" id="exampleModalLabel">
                    Connect your Wallet
                  </h3>
                  <button
                    type="button"
                    class="close cross-img1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <img
                        src="\adress\cross-icon.svg"
                        className="img-fluid "
                      />
                    </span>
                  </button>
                </div>

                <div className="button-modal1 d-flex">
                  <button className="modal-button" onClick={connectMetamask}>
                    <img
                      src="\adress\metamask-icon.svg"
                      className="img-fluid"
                    />
                    <h3 className=""> MetaMask</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                  <button className="modal-button" onClick={trustWallet}>
                    <img
                      src="\adress\walletconnect-icon.svg"
                      className="img-fluid"
                    />
                    <h3 className="">WalletConnect</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                </div>
                <p className="text-center mt-3  connecting">
                  By connecting, I accept HYDT{" "}
                  <span className="common connectinggg">Terms of Service </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <Offcanvas placement="top" className='mobile_sidebar' show={showsidebar} onHide={handleCloseSidebar}>
        <Offcanvas.Header closeButton>
          <div className="top-content">
            <a href="#" className="navbar-brand ">
              <Link to="/">
                <img
                  src="\DNS-logo.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Link>
            </a>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="navbar-nav mr-auto">
            <li class="nav-item px-0 ">
              <Link to="/">Home</Link>
            </li>
            <li className="nav-item px-0 ">
              <Link to="/"> Features</Link>
            </li>
            <li className="nav-item px-0 ">
              <Link to="/">About</Link>
            </li>
            <li className="nav-item px-0 ">
              <Link to="/"> FAQs</Link>
            </li>

          </ul>
          <a
            class="btn button-hedaer"
            href="https://app.hydtprotocol.com/"
                    target="_blank"
          >
            <img
              src="\luchbtnn.svg"
              alt="img"
              className="img-fluid mx-2"
            />
            Launch App
          </a>
        </Offcanvas.Body>
      </Offcanvas>

    </section>
  );
};

export default Navbar;
