import React, { useEffect } from "react";
import './giveaway.scss';

import AOS from "aos";
import "aos/dist/aos.css";
const Giveaway = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <section className="main-give">
                <div className="container-fluid">
                    <div className='row'>
                        <div className="col-xl-7 col-sm-10 col-12 m-auto p-0">
                            <div className='seccard_hadng'>
                                <div class="animate__animated animate__bounce animate__delay-1s"> <h3 className='cchoose '>Why choose HYDT Protocol</h3></div>
                                {/* <h3 className='cchoose animate__backInLeft'>Why Choose HYDT Protocol</h3> */}
                                <p className='accusantium'>
                                Check out what makes HYDT Protocol the best platform to earn a yield on your stablecoin
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-12 p-0 ">
                                <div className="all-cards ">
                                    <div className="main-card " data-aos="zoom-in-right" data-aos-duration="3000">
                                        <img src="\abstract.svg" alt="img" className='img-fluid' />
                                        <h6>Earn 30%+ APY on your stablecoin</h6>
                                        <p>HYDT Protocol offers high and attractive returns on your stablecoins. Anyone can earn 30%+ APY by purchasing HYDT stablecoin on pancakeswap or any other exchange and depositing it into the protocol, making it one of the most attractive APYs in the industry.</p>
                                    </div>
                                    {/* <img src="\mint-cards\Line.svg" alt="img" className='img-fluid line' /> */}
                                    <div className="main-card " data-aos="zoom-out" data-aos-duration="3000">
                                        <img src="\abstract2.svg" alt="img" className='img-fluid' />
                                        <h6>Flexible and Durable Soft Peg Mechanism</h6>
                                        <p>HYDT, the stablecoin of the HYDT protocol, has it's unique and flexible automated soft peg mechanism to keep HYDT close to 1USD while avoiding critical damage to the protocol even under the most extreme market conditions, which never stops working until HYDT regains its peg to the USD in case of a depeg.</p>
                                    </div>
                                    {/* <img src="\mint-cards\Line.svg" alt="img" className='img-fluid line' /> */}
                                    <div className="main-card" data-aos="zoom-in-left" data-aos-duration="3000">
                                        <img src="\abstract3.svg" alt="img" className='img-fluid' />
                                        <h6>Earning yield made easy</h6>
                                        <p>You just need to buy HYDT on the market and make a deposit to start earning yield on HYDT stablecoin, enjoy high returns without worrying about liquidation and complicated operations!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Giveaway;
