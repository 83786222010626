import React, { useState, useEffect } from "react";
import "./computerb.scss";
import AOS from "aos";
import "aos/dist/aos.css";
const Computerb = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="main-Computerb " id="about">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-11 p-0 m-auto ">
              <div className="row">
                <div className="col-xl-6 col-12 order text-center padd-sm">
                  <div className="aboutusimgg " data-aos="fade-right"
                   data-aos-easing="linear"
                    data-aos-duration="1000">
                    <img
                      src="shadowabout.png"
                      alt="img"
                      className="img-fluid left-img"
                    />
                    <img
                      src="about.png"
                      alt="img"
                      className="img-fluid left-img  aaaboutus_imgs"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-12 m-auto padd-sm">
                  <div className="right m-auto aboutvvccc " data-aos="fade-down-left"     data-aos-easing="linear"
                    data-aos-duration="1000" style={{maxWidth: "576px"}} >
                    <p className="aboutvv ">
                      About Us
                    </p>
                    <h2 className="sit">What is HYDT Protocol?</h2>
                    <h5 className="tempore">Designed to achieve high yield and price stability, making stablecoin yield farming simple and easy.</h5>
                    <p className="inventore">
                    The name HYDT stands for High Yield Dollar stable Token, and creating a USD pegged stablecoin with high yield is what the protocol is trying to achieve.
The protocol is built to maintain 16-30% APY even without governance token farming, simply by depositing HYDT stablecoin into the protocol's earn feature. 
While the protocol offers a high yield on deposits, it also has a unique and innovative peg mechanism to keep the HYDT price close to 1USD, backed by BNB reserve balance of the protocol.

                    </p>
                    {/* <p className="inventore pt-4">
                      Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut  officiis debitis aut.
                    </p> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Computerb;
