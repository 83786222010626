import React, { useEffect } from "react";
import './affiliateprogram.scss'
import AOS from "aos";
import "aos/dist/aos.css";

const Affiliateprogram = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <section className="main-Computerb affiliateprogram" id="about">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-11 p-0 m-auto ">
                            <div className="row">

                                <div className="col-xl-6 col-lg-12 col-12 m-auto padd-sm">
                                    <div className="right m-auto aboutvvccc " data-aos="fade-down-left" data-aos-easing="linear"
                                        data-aos-duration="1000" style={{ maxWidth: "576px" }} >
                                        <p className="aboutvv ">
                                            Affiliate Program
                                        </p>
                                        <h2 className="myhead">HYDT PROTOCOL AFFILIATE PROGRAM</h2>
                                        <p className="mypara">
                                            High  Stablecoin Staking Yield for Users. High Commission Rate for Promoters, Up to 5 level deep!
                                        </p>
                                        <h5 className="myparahead">Promoter’s annual earning from referral’s Stablecoin Staking.</h5>
                                        <p className="listmain">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                                <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 10 0.5)" fill="#1777E2" />
                                            </svg>
                                            5 Level referral commission.
                                        </p>
                                        <p className="listmain">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                                <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 10 0.5)" fill="#1777E2" />
                                            </svg>
                                            Earn 12-13.5% annually from direct referral.
                                        </p>
                                        <p className="listmain">
                                            <p className="listmain">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                                <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 10 0.5)" fill="#1777E2" />
                                            </svg>
                                            Earn 1.5-3.5% annually from indirect referral.
                                        </p>
                                        </p>
                                        {/* <p className="inventore pt-4">
                      Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut  officiis debitis aut.
                    </p> */}

                                    </div>
                                </div>
                                <div className="col-xl-6 col-12 order text-center padd-sm">
                                    <div className="aboutusimgg " data-aos="fade-right"
                                        data-aos-easing="linear"
                                        data-aos-duration="1000">
                                        <img
                                            src="\assets\affiliateprogramimg.png"
                                            alt="img"
                                            className="img-fluid left-img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Affiliateprogram
