import { useEffect } from 'react';
import "./getinvolved.scss";

import AOS from "aos";
import "aos/dist/aos.css";
export const Getinvolved = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>

      <section className="secondlast">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-12 col-11  p-0 m-auto padd-sm">
              <div className="main-bodyyy p-0" style={{ overflow: "hidden" }}>
                <div className="row">
                  <div className="col-xl-6 col-12  m-auto">
                    <div className="parent">
                      <h2>Join Our HYDT Community </h2>
                      <div className="lastbtnbnrs ">
                      <a href='https://discord.gg/tGPGBCYGyy' target='_blank' className="transparent-btn " data-aos="fade-up" data-aos-duration="3000">
                        <img
                          src="\Component 4.svg"
                          alt="img"
                          className="img-fluid mx-2"
                        />
                        Join Discord
                
                      </a>
                      <a href='https://t.me/hydtprotocolgroup' target='_blank' className="transparent-btn" data-aos="fade-up" data-aos-duration="3000">
                        <img
                          src="\telegram.svg"
                          alt="img"
                          className="img-fluid mx-2"
                        />
                 
                        Telegram group
                      </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6  col-12 m-auto padd-sm">
                    <div className="parentss"  >
                      <div className="desktop-img text-center" data-aos="fade-left" data-aos-duration="3000">
                        <img src="/join-us-shadow.png" alt="img" className="join-shadow" />
                        <img src="/joib-us.png" alt="img" className="img-fluid " />
                      </div>
                      <div className="mobile-img text-center d-none">
                        <img src="\mobile-join-shadow.png" alt="img" className="join-shadow" />
                        <img src="\without-shadow-join.svg" alt="img" className="img-fluid " />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};
export default Getinvolved;
