import React, { useEffect } from "react";
import "./adress.scss"
import AOS from "aos";
import "aos/dist/aos.css";
export const Adress = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <section className="main-adress ">
                <div className='container-fluid '>
                    <div className="row">
                        <div className="col-xl-11 col-11 m-auto p-0 padd-sm">
                            <div className='row'>
                                <div className='col-sm-8 mx-auto'>
                                    <div className='heading'>
                                        <h2 className='ggeetttt'>HYDT Protocol Tokenomics</h2>
                                        {/* <p className='misskar'>Omnis iste natus error sit voluptatem accusantium doloremque laudant totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae perspiciatis dicta sunt explicabo.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xl-6 col-12 m-auto p-0 '>
                                    <div className="all-cards bgblue" data-aos="fade-right">
                                        <div className="main-card">
                                            <img src="\bluecrd.svg" alt="img" className='img-fluid' />
                                            <h6>HYDT</h6>
                                            <p>HYDT (High Yield Dollar stable Token) is the core of the HYDT protocol, pegged to the United States Dollar in a flexible and unique way through the HYDT protocol's Mint and Redeem process. You can earn 16-30%+ APY by depositing HYDT on Stake HYDT feature. You can get HYDT by using Initial Mint, purchasing it on Pancakeswap or any other exchanges.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-6 col-12 m-auto p-0 padd-sm'>
                                    <div className="all-cards bgorange" data-aos="fade-left">
                                        <div className="main-card">
                                            <img src="\orngcard.svg" alt="img" className='img-fluid' />
                                            <h6>HYGT</h6>
                                            <p>HYGT (High Yield Governance Token) is the governance token of the HYDT protocol. Users will earn HYGT by depositing HYDT to Stake HYDT feature or staking Pancakeswap LP
                                                token on Farm feature. Voting feature for HYGT holders is planned to be launched in Q1 2024, allowing HYGT holders to decide on the development of new features and important para
                                                meter changes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
export default Adress;
