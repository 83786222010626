import React from 'react';
import './faqs.scss';
const Faqs = () => {
    return (
        <>
            <section className="main-faqs " id="faqs">
                <div className="container">
                    <div className='row'>
                        <div className='col-10 m-auto p-0'>
                            <div className="highlightsxxxx">
                                <h2 className='Pioneersss'>Frequently Asked Questions</h2>
                                {/* <p className=' faqs_para'>Omnis iste natus error sit voluptatem accusantium doloremque laudant totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae perspiciatis dicta sunt explicabo.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 m-auto padd-sm'>
                            <div className=" inner-upper p-0">
                                <div className="inner-side ">
                                    <div id="accordion">
                                        <div className="card gradient-box openclose">
                                            <div className="card-header" id="headingTwo">
                                                <div className="border-divssss">
                                                    <button className="btn  main-button-mm  d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className='main-dd'>
                                                            <div className='inner-main-left'>
                                                                {/* <div className='inner-left'><span className=''></span> </div> */}
                                                                <div className='inner-right'> <h5 className="ffaaqqss">
                                                                    How is HYDT pegged to 1 USD?</h5></div>
                                                            </div>
                                                            <div className='inner-main-right mmm'><p className=''><span className='plussss '>+</span></p></div>
                                                            <div className='inner-main-right mmmm'><p className=''><span className='minus-sign '>-</span></p></div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div className="card-body">
                                                    Protocol will automatically buy back and burn HYDT with reserve balance when HYDT price is below peg range, and mint and sell HYDT when HYDT price is above peg range. Check details on our gitbook document
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card openclose">
                                            <div className="card-header" id="headingThree">
                                                <div className="border-divssss">
                                                    <button className="btn btn-link main-button-mm collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <div className='main-dd'>
                                                            <div className='inner-main-left'>
                                                                {/* <div className='inner-left'><span className=''>02.</span> </div> */}
                                                                <div className='inner-right'>
                                                                    <h5 className="">
                                                                        Is a 1:1 redemption always possible?</h5>
                                                                </div>
                                                            </div>
                                                            <div className='inner-main-right mmm'><p className=''><span className='plussss '>+</span></p></div>
                                                            <div className='inner-main-right mmmm'><p className=''><span className='minus-sign '>-</span></p></div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div className="card-body">
                                                    HYDT can be exchanged for other USD stablecoins on exchanges, but the protocol does not provide direct 1:1 redemption for users. While the protocol will automatically mint/redeem HYDT to make the HYDT price close to 1 USD, your redemption rate will depend on the HYDT/USD exchange rate at the time.                                                  </div>
                                            </div>
                                        </div>
                                        <div className="card openclose">
                                            <div className="card-header" id="headingfive">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link main-button-mm collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className='main-dd'>
                                                            <div className='inner-main-left'>
                                                                {/* <div className='inner-left'><span className=''>03.</span> </div> */}
                                                                <div className='inner-right'>
                                                                    <h5 className="">
                                                                        Is the HYDT protocol sustainable?</h5>
                                                                </div>
                                                            </div>
                                                            <div className='inner-main-right mmm'><p className=''><span className='plussss '>+</span></p></div>
                                                            <div className='inner-main-right mmmm'><p className=''><span className='minus-sign '>-</span></p></div>
                                                        </div>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsefive" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                                <div className="card-body">
                                                    While offering a higher APY than most stablecoin farms, the HYDT protocol has a unique and flexible soft-peg mechanism that effectively eliminates the risk of running out of reserves to avoid HYDT becoming worthless. The protocol will continue to work under extreme market conditions to keep depegging to a minimum.                   </div>
                                            </div>
                                        </div>
                                        <div className="card openclose">
                                            <div className="card-header" id="headingsix">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link main-button-mm collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className='main-dd'>
                                                            <div className='inner-main-left'>
                                                                {/* <div className='inner-left'><span className=''>04.</span> </div> */}
                                                                <div className='inner-right'>
                                                                    <h5 className="">
                                                                        How do you mint HYDT?</h5>
                                                                </div>
                                                            </div>
                                                            <div className='inner-main-right mmm'><p className=''><span className='plussss '>+</span></p></div>
                                                            <div className='inner-main-right mmmm'><p className=''><span className='minus-sign '>-</span></p></div>
                                                        </div>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsesix" className="collapse" aria-labelledby="headingsix" data-parent="#accordion">
                                                <div className="card-body">
                                                    When the initial mint feature is available, you can mint HYDT by paying the BNB at a rate of 1HYDT/USD.
                                                    After the initial mint feature is closed, the only way to obtain HYDT is to buy it on the market.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card openclose">
                                            <div className="card-header" id="headingseven">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link main-button-mm collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseseven" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className='main-dd'>
                                                            <div className='inner-main-left'>
                                                                {/* <div className='inner-left'><span className=''>05.</span> </div> */}
                                                                <div className='inner-right'>
                                                                    <h5 className="">
                                                                        Why is the HYDT deposit locked for a certain period of time?</h5>
                                                                </div>
                                                            </div>
                                                            <div className='inner-main-right mmm'><p className=''><span className='plussss '>+</span></p></div>
                                                            <div className='inner-main-right mmmm'><p className=''><span className='minus-sign '>-</span></p></div>
                                                        </div>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseseven" className="collapse" aria-labelledby="headingseven" data-parent="#accordion">
                                                <div className="card-body">
                                                    HYDT deposit offers 3 staking plans with 3-12 month lockup period, paying out yield and staking principal at the same time. This system is designed to avoid bank run events which can cause huge depegging, by not allowing users to exchange full amounts of their HYDT staking to USD in a short period of time. Protocol encourages users to deposit their HYDT in longer term staking plans by offering higher APY for longer lock period so that HYDT price could be stabilized further.

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Faqs;
