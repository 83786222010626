import { useEffect } from 'react';
import './roadmap.scss';
import AOS from "aos";
import "aos/dist/aos.css";
export const Roadmap = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="roadmap" id='roadmap'>
        <div class="container timeline">
          <div className="row">
            <div className=" col-12 col-xl-10 col-md-12 col-sm-6 m-auto">
              <h1 class="timeline__heading " data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500">HYDT Protocol Roadmap</h1>
              <p className='inventorexx'>The HYDT protocol development team has a long-term plan to expand the HYDT protocol to a wider range of crypto users and make the protocol the top1 choice for stablecoin yield farming.</p>
              <div class="timeline__section">
                <div class="row timeline__point timeline__gutter flex-md-row-reverse wow bounceInUp mt-5 "
                >
                  <div class="col-md-6">
                    <div className="maindivcc" data-aos="fade-left"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <h2 class="timeline__title ">Q2 2023</h2>
                      <ul className="styledott timeline__text">
                        {' '}
                        <p class="">
                          <li className="liiisss">
                          Core contract development completion : Completed
                          </li>
                          <li className="liiisss">
                          Testnet launch : Completed
                          </li>
                
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row timeline__point timeline__gutter wow bounceInUp mt-5" >
                  <div class="col-md-6">
                    <div className="maindivcc" data-aos="fade-right"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <h2 class="timeline__title ">Q3 2023</h2>
                      <ul className="styledott timeline__text">
                        {' '}
                        <p class="">
                          <li className="liiisss">
                          Smart contract security Audit: Completed
                          
                          </li>
                          <li className="liiisss">Mainnet deployment and frontend integration : Completed</li>
                          {/* <li className="liiisss">
                          HYDT protocol official mainnet launch: Coming soon
                          </li> */}
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row timeline__point timeline__gutter flex-md-row-reverse wow bounceInUp mt-5">
                  <div class="col-md-6">
                    <div className="maindivcc"  data-aos="fade-left"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <h2 class="timeline__title ">Q4 2023</h2>
                      <ul className="styledott timeline__text">
                        {' '}
                        <p class="">
                        <li className="liiisss">
                        HYDT protocol official launch : Completed
                          </li>
                          <li className="liiisss">
                          HYGT reward launch : Completed
                          </li>
                          <li className="liiisss">
                          HYDT and HYGT Centralized exchange listing : Completed
                          </li>
                          
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row timeline__point timeline__gutter wow bounceInUp mt-5">
                  <div class="col-md-6">
                    <div className="maindivcc"  data-aos="fade-right"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <h2 class="timeline__title ">Q1 2024</h2>
                      <ul className="styledott timeline__text">
                        {' '}
                        <p class="">
                          <li className="liiisss">
                          Governance token buy-back activation: Completed
                          </li>
                          {/* <li className="liiisss">
                            {' '}
                            Governance token buy-back activation
                          </li>
                          <li className="liiisss">
                            {' '}
                            Multi chain deployment 
                          </li> */}
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row timeline__point timeline__gutter flex-md-row-reverse wow bounceInUp mt-5">
                  <div class="col-md-6">
                    <div className="maindivcc"  data-aos="fade-left"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <h2 class="timeline__title ">Q2 2024</h2>
                      <ul className="styledott timeline__text">
                        {' '}
                        <p class="">
                          <li className="liiisss">
                          Affiliate Program Development: Completed
                          </li>
                          <li className="liiisss">
                          Mint HYDT function Re-activation: Completed
                          </li>
                          {/* <li className="liiisss">
                          HYDT Listing on Lending protocols
                          </li> */}
                          
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row timeline__point timeline__gutter wow bounceInUp mt-5">
                  <div class="col-md-6">
                    <div className="maindivcc"  data-aos="fade-right"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <h2 class="timeline__title ">Q3 2024</h2>
                      <ul className="styledott timeline__text">
                        {' '}
                        <p class="">
                          <li className="liiisss">
                          Affiliate Program Launch: Completed
                          </li>
                          <li className="liiisss">
                            {' '}
                            User interface improvement: Completed
                          </li>
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row timeline__point timeline__gutter flex-md-row-reverse wow bounceInUp mt-5">
                  <div class="col-md-6">
                    <div className="maindivcc"  data-aos="fade-left"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <h2 class="timeline__title ">Q4 2024</h2>
                      <ul className="styledott timeline__text">
                        {' '}
                        <p class="">
                          <li className="liiisss">
                          HYDT Staking APY Boost
                          </li>
                          <li className="liiisss">
                          HYDT Protocol NFT
                          </li>
                          
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row timeline__point timeline__gutter wow bounceInUp mt-5">
                  <div class="col-md-6">
                    <div className="maindivcc"  data-aos="fade-right"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <h2 class="timeline__title ">2025</h2>
                      <ul className="styledott timeline__text">
                        {' '}
                        <p class="">
                          <li className="liiisss">
                          HYDT cross chain bridge implementation
                          </li>
                          <li className="liiisss">
                            {' '}
                            Multi currency reserve implementation
                          </li>
                          <li className="liiisss">
                            {' '}
                            Adding stable swap LP farm
                          </li>
                          <li className="liiisss">
                            {' '}
                            HYDT Protocol DAO Launch
                          </li>
                          <li className="liiisss">
                            {' '}
                            HYDT Listing on Lending protocols
                          </li>
                          <li className="liiisss">
                            {' '}
                            HYDT Staking APY Boost
                          </li>
                          <li className="liiisss">
                            {' '}
                            HYDT Protocol NFT
                          </li>
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Roadmap;
